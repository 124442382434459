@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  background-color: #f5f5f5;
  color: #121416;
  padding-bottom: 60px;
}

* {
  margin: 0;
  padding: 0;
}

$primaryColor: "#2B1DE8";
$font: "Montserrat";

body {
  .MuiInputBase-input {
    font-size: 15px !important;
  }

  // .MuiInputBase-root {
  //   font-family: "Roboto", sans-serif !important;
  //   font-weight: 500;
  // }

  // .MuiInputLabel-outlined {
  //   transform: translate(14px, 13px) scale(1);
  // }
}

h6 {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 16px;
}

.TextField {
  width: 100%;
  border-color: #ececec;

  label {
    width: auto !important;
  }

  label .Mui-disabled {
    color: rgba(0, 0, 0, 0.37);
  }

  .Mui-disabled {
    color: rgba(0, 0, 0, 0.87);
  }
}

.formPostjob {
  padding: 0 !important;

  &.formPostjobPadding {
    .btnWhatapp {
      position: static;
      padding-bottom: 5px;
      padding-top: 10px;
      background: transparent;
    }
  }

  label {
    font-size: 15px;
    width: 145px;
  }

  .MuiTypography-root {
    font-size: 15px !important;
  }

  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: solid;
    border-color: #ccc;
  }

  .textareaCustom {
    border: none;
    border-bottom: 1px solid;
    padding: 6px 0 7px;
  }

  .error {
    font-size: 13px;
    font-weight: 500;
    color: #f44336;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.error {
  font-size: 13px;
  font-weight: 500;
  color: #f44336 !important;
  margin-top: 4px;
  display: block;
}

.MuiFormControl-root {
  display: flex !important;
}

.stickysearch {
  background: #fff;
  max-width: 600px;
  margin: 0 auto -1px;
  padding: 16px 16px 0;
}

body fieldset[aria-hidden] {
  border-color: #ececec;
}

.seachInput {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .txtSearch {
    background: #f5f5f5;
  }

  .MuiTextField-root {
    display: flex;
    flex: 1;
  }

  input {
    margin-left: 7px;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  //   position: relative;
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  //   grid-gap: 30px;
  //   @media (max-width: 767px) {
  //     grid-template-columns: 1fr;
  //   }
  > div {
    position: relative;
  }

  .css-wj4ovw-Meridiems,
  .css-8xneil-Meridiems {
    background: #89ba3f;
    color: #fff;
  }

  .css-3u52cg-TopBar,
  .css-1uynwhj-TopBar {
    color: #89ba3f;
  }

  .react-timekeeper__done-button {
    background: #89ba3f;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.25em;
    font-size: 13px;
    height: 40px;
    padding: 0;
    line-height: 40px;
  }

  // line {
  //   stroke: #89ba3f;
  // }

  // circle {
  //   fill: #89ba3f;
  // }
  .react-timekeeper {
    margin-top: 10px;
    position: absolute;
    right: 0;
    z-index: 3;
  }

  .timeFlex {
    display: flex;
    align-items: center;

    label {
      margin-bottom: 0;
    }

    span {
      padding: 0.375rem 0.75rem;
      border: 1px solid #ddd;
      width: 100%;
      height: 37px;
      font-weight: 600;
      border-radius: 0.25rem 0 0 0.25rem;
    }

    .btn {
      height: 37px;
      padding: 0px;
      min-width: 60px;
      border-radius: 0 0.25rem 0.25rem 0;
      font-size: 15px;
      letter-spacing: 0;
    }
  }
}

$spCol: #fff;

.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;

  .box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
  }

  .sp-circle {
    width: 32px;
    height: 32px;
    border: 4px rgba($spCol, 0.35) solid;
    border-top: 4px rgba($spCol, 1) solid;
    border-radius: 50%;
    -webkit-animation: spCircRot 0.6s infinite linear;
    animation: spCircRot 0.6s infinite linear;
  }
}

@-webkit-keyframes spCircRot {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

@keyframes spCircRot {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.appBar {
  position: "relative";
  background: #832cff !important;
}

.title {
  flex: 1;
}

.listJob {
  background: #fff;

  .listcontent {
    padding: 12px 16px;
    border-top: 1px solid #ececec;
  }

  li:first-child {
    .listcontent {
      border: none;
    }
  }

  .avatar {
    width: 60px;
    height: 60px;
    margin-right: 16px;
  }

  .title {
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 0;
  }

  .time {
    font-weight: 500;
    color: #832cff;
    font-size: 14px;
  }

  .leave-review {
    color: red;
    font-size: 14px;
    display: flex;
  }

  .show-applications {
    color: #25d366;
    font-size: 14px;
  }

  .content {
    flex: 1;

    .location {
      display: flex;
      justify-content: space-between;
      font-size: 14px;

      span {
        display: flex;
        justify-content: flex-end;
        flex: 1;
      }
    }
  }
}

.location {
  display: block;
  opacity: 0.5;
  margin: 2px 0;
}

.time {
  font-weight: 700;
  color: #832cff;
  font-size: 16px;
  display: block;
}

.title {
  margin-bottom: 20px;
  text-align: center;

  h2 {
    font-size: 18px;
  }

  h4 {
    color: #832cff;
    font-weight: 600;
    font-size: 15px;
  }
}

.fwbold {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.followbtn {
  display: flex;
  align-items: center;
  min-height: 47px;
  button {
    opacity: 0.9;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.8);
    text-transform: inherit;
  }
}
.followbtnGig {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  button {
    color: #fff;
    background-color: #832cff !important;
    text-transform: inherit;
  }
}
.follow {
  max-width: 600px;
  padding: 0 0 16px;
  margin: auto;

  h5 {
    font-weight: 600;
    font-size: 14px;
    margin: 0 16px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .box {
    background: #fff;
    white-space: nowrap;
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 16px 0;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
      display: none;
    }

    div.grid {
      display: inline-block;
      margin-left: 20px;
      text-align: center;
      font-size: 13px;

      &:last-child {
        margin-right: 20px;
      }
    }
  }

  .group {
    display: flex;
    align-items: flex-start;
    grid-gap: 16px;
    padding: 20px 16px;
    background: #fff;

    .content {
      text-align: center;
      width: 100%;

      h4 {
        margin-bottom: 14px;
        font-weight: 600;
      }

      .AvatarGroup {
        justify-content: center;
        margin-left: 7px;
      }

      .more {
        color: #832cff;
        font-size: 13px;
        margin-top: 12px;
      }
    }
  }
}

.boxUserProfileonEdit {
  background-color: #4a07aa !important;
}

.tabs {
  padding: 0 0 16px !important;

  ._tabs {
    background: #f5f5f5;
    position: relative;

    &::after {
      height: 3px;
      width: 100%;
      background: #fff;
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
    }
  }

  .txtTabs {
    text-transform: inherit;
    font-weight: 600;
  }

  .txtTabs[aria-selected="true"] {
    background: #fff;

    span {
      color: #832cff;
    }
  }

  .tabschild {
    position: relative;
    min-height: 29px;

    &::after {
      height: 3px;
      width: 100%;
      background: #fff;
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
    }

    [aria-label] {
      background: #f5f5f5;
      border-radius: 4px;
      min-height: auto;
      margin-bottom: 10px;

      .MuiTab-textColorInherit.Mui-selected {
        background: #832cff;
        border-radius: 5px;
        padding: 0;

        .MuiTab-wrapper {
          color: #fff;
          font-size: 13px;
        }
      }
    }

    button {
      min-height: 29px;
      padding: 0 5px;
      border-radius: 4px;
      text-transform: initial;
      font-weight: 500;
      font-size: 12px;
    }

    button[aria-selected="true"] {
      background: #832cff;
      color: #fff;
    }
  }

  .TabPanel {
    > div {
      padding: 16px;
    }

    .box > div {
      padding: 0;
    }
  }

  &.tabsFollows {
    .TabPanel > div {
      padding: 0;
    }

    .TabPanel {
      background: #fff;
    }
  }
}

.rating {
  display: flex;
  height: 100vh;
  justify-content: center;
  flex-direction: column;
}

.groupApplicants {
  .applicants {
    border-bottom: 1px solid #ececec;
    margin-bottom: 16px;
    padding-bottom: 16px;

    h3 {
      font-size: 15px;
      font-weight: 600;
      margin: 0 0 7px;
    }

    p {
      margin-bottom: 7px;
    }

    .button {
      display: flex;
      justify-content: space-between;

      > div,
      button {
        min-width: 100px;
      }
    }

    &:last-child {
      padding-bottom: 0;
      border: none;
    }
  }
}

.avatarUser {
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s;
  margin-top: -10px;

  .avatar {
    width: 80px;
    height: 80px;
    transition: all 0.3s;
  }

  input {
    opacity: 0;
    position: absolute;
    width: 80px;
    height: 80px;
    top: 0;
  }

  h5 {
    margin-top: 5px;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    transition: all 0.3s;
  }
}

.sort {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    align-items: center;
    display: flex;
    position: relative;
    right: -13px;
  }
}

.chip {
  > div > div:last-child {
    margin-bottom: 16px;
  }
}

.boxUserProfile {
  transition: all 0.3s;
  transition-timing-function: linear;
  max-width: 100%;
  width: 600px;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 16px;
  overflow: hidden;
  background: #832cff;
  // &::before {
  //   background: url('../images/bg.png');
  //   background-size: cover;
  //   background-position: bottom left;
  //   position: absolute;
  //   height: 280px;
  //   width: 100%;
  //   content: '';
  //   z-index: -1;
  // }

  .buttonEdit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0 0;

    div {
      color: #fff;
      font-weight: 600;
      text-align: right;
      cursor: pointer;
      opacity: 0.9;
      font-size: 14px;
    }
  }
}

@keyframes load {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

.profile-widgets {
  display: grid;
  animation: load 1s;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  text-align: center;
  margin-top: 10px;
  color: #fff !important;

  div {
    position: relative;

    &:last-child::before {
      width: 1px;
      height: 40px;
      content: "";
      position: absolute;
      left: 0;
      display: block;
      background: rgba(255, 255, 255, 0.5);
      top: 50%;
      margin-top: -20px;
    }
  }

  h5 {
    font-size: 15px;
    font-weight: 600;
    color: #fff;
  }

  h6 {
    font-size: 13px;
    color: #fff !important;
    font-weight: 400;
    opacity: 0.8;
    margin-bottom: 0px;

    span {
      display: block;
    }
  }
}

.sticky {
  z-index: 999;

  .name {
    span {
      margin-top: 0 !important;
    }
  }

  .profile-widgets {
    display: none;
  }

  .boxUserProfile {
    padding-bottom: 12px;
  }

  .avatarUser {
    margin-top: -40px;

    .avatar {
      width: 35px;
      height: 35px;
    }

    h5 {
      font-size: 14px !important;
    }
  }
}

.textfillBox {
  background: #fff;
  padding: 25px 16px 16px;
  margin-bottom: 16px;
}

.typographyflex {
  display: flex;
  margin: 5px 0 15px;
  font-size: 15px;
  flex-direction: column;

  &:last-child {
    margin-bottom: 7px;
  }

  span {
    opacity: 0.75;
    min-width: 100px;
    margin-right: 5px;
    font-size: 14px;
    margin-bottom: 5px;

    &.multiline {
      white-space: pre-line;
      opacity: 1 !important;
      font-size: 15px;
      line-height: 1.6;
    }
  }

  &.flexcolm {
    flex-direction: column;

    span {
      margin-bottom: 5px;
    }
  }
}

.dialog {
  h2 {
    font-size: 15px;
  }
}

.stepbg {
  > div {
    margin-top: 20px;
  }

  div,
  a,
  span {
    line-height: 25.25px !important;
  }

  .Stepper {
    background: transparent;
    padding: 20px 0 5px;

    span {
      font-size: 13px;
    }
  }
}

:focus {
  outline: none;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 18px;
  height: 18px;
}

.loader {
  margin: 30px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 4px solid rgba(131, 44, 255, 0.3);
  border-right: 4px solid rgba(131, 44, 255, 0.3);
  border-bottom: 4px solid rgba(131, 44, 255, 0.3);
  border-left: 4px solid #832cff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.listFilter {
  background: #f5f5f5;
  height: 100%;

  .cardHeader {
    background: #f5f5f5;
    border-bottom: 1px solid #ececee;
  }

  .ExpansionPanel {
    border-top: 1px solid #ececec;
  }

  .MuiChip-root {
    background: #832cff;

    span {
      color: #fff;
    }

    svg {
      color: rgba(255, 255, 255, 0.26);
    }
  }

  .btnClear {
    display: flex;
    justify-content: flex-end;
    padding-right: 16px;
    padding-top: 20px;
    padding-bottom: 10px;
    border-top: 1px solid #ececec;
  }
}

.reviews {
  background: #fff;
  padding: 16px;
  margin-bottom: 16px;
  margin-top: 16px;

  h5 {
    font-size: 15px;
    margin-bottom: 7px;
  }

  .star-progress-bar {
    padding: 0;
    margin: 0;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px;
      white-space: nowrap;
      font-size: 13px;

      > div {
        width: 100%;
        margin: 0 8px;
      }

      p {
        width: 40px;
        display: flex;
        align-items: center;

        label {
          width: 15px;
          margin-right: 2px;
          text-align: center;
        }

        svg {
          width: 17px;
          height: 17px;
        }
      }

      span {
        width: 50px;
      }
    }
  }

  .rateout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #999;
    font-size: 14px;
    align-items: center;

    p {
      font-size: 26px;
      color: #121416;
      font-weight: 600;

      span {
        font-size: 14px;
        color: #999;
        font-weight: 500;
      }
    }
  }

  .btnReview {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    color: #999;
    align-items: center;
  }
}

.comment {
  margin-top: 16px !important;
  padding-top: 16px !important;
  border-top: 1px solid #ececec;

  li {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #ececec;
    padding: 16px 0;
    font-size: 14px;

    .content {
      margin-left: 10px;
      position: relative;
      width: 100%;
      overflow: hidden;

      h5 {
        display: flex;
        font-size: 14px;
        align-items: center;
        position: relative;
        width: calc(100% - 30px);

        .time {
          font-weight: 500;
          color: #999;
          font-size: 12px;
          margin-left: 8px;
        }
      }

      p {
        opacity: 0.85;
      }

      .icon {
        position: absolute;
        right: -40px;
        opacity: 0.8;

        svg {
          font-size: 20px;
        }
      }
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border: none;
    }
  }
}

.ratingbox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid #ececec;
  padding: 30px 16px 75px;
  height: 100%;

  h4 {
    font-weight: 600;
    font-size: 20px;
  }

  label span {
    font-size: 35px;
  }

  .type {
    width: 100%;
    margin: 16px 0;

    .textarea {
      width: 100%;
      // background: #f5f5f5;

      textarea {
        min-height: 80px;
      }
    }
  }
}

.contentDetail {
  .ExpansionPanel {
    background: #fff;
  }

  .ExpansionPanelSummary {
    background: #fff;
  }

  .ExpansionPanelDetails {
    border-top: 1px solid #ececec;
    background: #fff;
  }
}

.ios {
  padding-bottom: 20px !important;

  .btnWhatapp {
    padding-bottom: 20px !important;
  }
}

.btnWhatapp {
  background-color: rgb(255, 255, 255);
  padding-bottom: 75px;
  padding-top: 15px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 3;
}

.btnDetails {
  background-color: white;
  padding: 16px 16px 16px;
  position: sticky;
  bottom: 0;
  left: 0px;
  width: 100%;
  z-index: 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;

  .btnLonger {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  button {
    margin: 0 !important;
  }

  button:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .btn3 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    grid-column-start: 1;
    grid-column-end: 4;
    .clm3 {
      display: grid;
      grid-template-columns: 38% 38% 24%;

      button {
        width: 100%;
        height: 100%;
      }
      .edit {
        background-color: #ffbf00;
      }
    }
  }
  .btn2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    grid-column-start: 1;
    grid-column-end: 3;

    button {
      margin: 0 !important;
    }

    button:nth-child(2) {
      background-color: #25d366;
      color: #fff;

      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.26);
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.12);
      }
    }

    button:nth-child(3) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}

.gigChild {
  width: 100%;

  // margin-top: 16px;
  h6 {
    margin-top: 16px;
  }

  .listJob {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.switchDarkMode {
  display: none;
}

.dashboard {
  max-width: 600px;
  margin: 0 auto;

  h2 {
    padding: 12px 16px;
    font-size: 15px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      font-size: 14px;
      text-transform: inherit;
    }
  }

  .listJobscroll {
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    scrollbar-width: none;
    box-sizing: content-box;
    background: transparent !important;

    &::-webkit-scrollbar {
      display: none;
    }

    .avatar {
      margin: 0 0 6px;
      width: 140px;
      height: 140px;
    }

    .content {
      margin-top: 4px;
      height: 100%;
      display: flex;
      max-width: 140px;

      .time,
      .title {
        max-width: 140px;
      }
    }

    li {
      background: #fff;
      display: grid;
      margin-left: 16px;
      grid-gap: 5px;
      border: none;
      align-items: flex-start;
      border: none;

      .listcontent {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 10px;
        border: none;
        align-items: flex-start;

        .location {
          display: block;

          span {
            justify-content: flex-start;
          }
        }
      }

      // .time {
      //   max-width: 140px;
      // }

      &:last-child {
        border-right: 16px solid #f5f5f5;
      }
    }
  }
}

.SideCategories {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  scrollbar-width: none;
  box-sizing: content-box;
  flex-wrap: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }

  li {
    display: grid;
    margin-right: 16px;
    grid-template-rows: 1fr 1fr;
    align-items: start;
    grid-gap: 16px;

    &:first-child {
      margin-left: 16px;
    }

    &:last-child {
      border-right: 16px solid #f5f5f5;
    }

    .item {
      display: flex;
      background: #fff;
      border: none;
      width: 140px;
      position: relative;
      overflow: hidden;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      height: 100%;
      padding: 0;
      border-radius: 0 0 4px 4px;

      p {
        word-break: break-word;
        text-align: center;
        font-size: 14px;
        padding: 7px 5px;
        height: calc(100% - 140px);
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
      }

      img {
        width: 140px;
        height: 140px;
        object-fit: cover;
        border-radius: 4px 4px 0 0;
      }
    }
  }
}

.links {
  display: flex;
  font-size: 15px;
  flex-direction: column;
  max-width: 600px;
  margin: auto;

  > div {
    justify-content: space-between !important;
    border-bottom: 1px solid #f5f5f5;
  }

  > div + div > div {
    padding-top: 17px;
    padding-bottom: 17px;
    justify-content: space-between !important;
    display: flex;
  }

  li {
    padding-top: 17px;
    padding-bottom: 17px;
    justify-content: space-between !important;
    display: flex;
    border-bottom: 1px solid #f5f5f5;
  }

  background-color: #fff;
}

.About {
  max-width: 660px;
  position: fixed;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  margin: auto;
  height: 100vh;

  .content {
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    padding: 20px 20px 0;
    align-items: center;
    background-color: #f5f5f5;
    background-image: url(../images/bgLight1.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;

    &::after {
      content: "";
      background-image: url(../images/bgLight2.svg);
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 166px;
      height: 200px;
    }

    .box {
      width: 100%;
      padding: 0 30px;
      font-size: 15px;
      min-height: 360px;

      img {
        max-width: 100%;
        width: 280px;
      }

      p {
        margin: 8rem 0 10px;
      }

      .email {
        text-decoration: underline;
        color: #121416;
      }
    }

    .close {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: -10px;
      margin-right: -20px;
    }
  }
}

.logOut {
  padding: 20px 16px;
  display: flex;
  justify-content: flex-end;
  max-width: 600px;
  margin: auto;

  button {
    text-transform: inherit;
    font-size: 15px;
  }
}

.width {
  max-width: 600px;
  margin: auto;
}

.tabsMygigs {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 3;
}

.stickytabs {
  top: 70px;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 1;

  .txtSearch {
    background: #f5f5f5;
  }

  .width {
    background: #fff;
    padding: 16px 16px 0;

    h6 {
      margin-top: 0;
    }
  }
}

.mt {
  //margin-top: 210px;
  margin-top: 145px;
}

.GigAlert {
  border-top: 16px solid #f5f5f5;
  border-bottom-width: 16px !important;
  color: #832cff;
  font-weight: 600;
}

.post-gig-form label {
  width: 100%;
}

.TabPanel {
  .form-group:last-child {
    // display: grid;
    // grid-template-columns: 162px 1fr;
    border-bottom: none;
  }
}

.form-group {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dedede;
  align-items: flex-start;
  margin: 0 16px;
  padding: 16px 0;
  width: 100%;

  p {
    text-align: right;
    opacity: 0.85;
  }

  &.form-flex {
    flex-direction: column;
  }

  label {
    font-weight: 600;
    font-size: 14px;
    margin-right: 20px;

    span {
      font-size: 13px;
      opacity: 0.75;
    }
  }

  .social {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    width: 100%;
    margin-top: 10px;

    p {
      opacity: 0.85;
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        margin-right: 7px;
      }

      img {
        width: 19px;
        height: 19px;
        background-size: 100%;
        margin-right: 9px;
        margin-left: 2px;
      }
    }
  }
}

.ChangePassword {
  max-width: 660px;
  position: fixed;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  margin: auto;
  height: 100vh;

  fieldset[aria-hidden] {
    border-color: rgba(0, 0, 0, 0.23);
  }

  &::before {
    background: url("../images/bg.svg");
    height: 100%;
    background-size: 100%;
    content: "";
    position: absolute;
    top: 50%;
    display: block;
    width: 100%;
    z-index: 1;
    background-repeat: no-repeat;
  }

  .content {
    height: 100%;
    flex-direction: column;
    display: flex;
    padding: 20px 0 0;
    background-color: #f5f5f5;

    .box {
      flex: 1 1;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 60vh;
      justify-content: center;
      text-align: center;
      position: relative;
      z-index: 3;

      img {
        margin-bottom: 20px;
      }

      input {
        background: #f5f5f5;
      }

      .button {
        width: 263px;
        margin: 20px auto;

        button {
          width: 100%;
          height: 42px;
        }
      }
    }

    .close {
      position: fixed;
      right: 10px;
      top: 10px;
      z-index: 6;
    }
  }
}

.empty {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  font-size: 13px;
  font-weight: 600;
  margin-top: 140px;
  background-color: #f5f5f5;

  img {
    margin-bottom: 10px;
    opacity: 0.75;
    margin-left: 10px;
  }
}

.errorPage {
  max-width: 660px;
  position: fixed;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  margin: auto;
  height: 100vh;
  display: flex;
  align-items: center;

  .content {
    text-align: center;
    width: 100%;

    h4 {
      // text-transform: uppercase;
      margin: 30px 0;
      opacity: 0.75;
      font-weight: 600;
      line-height: 1.6;
    }

    button {
      text-transform: unset;
      min-width: 215px;
    }

    .icon {
      background: url("../images/noconection.png");
      width: 70px;
      background-size: 100%;
      display: block;
      height: 70px;
      margin: auto;
      opacity: 0.5;
    }
  }
}

.cstandard {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 16px;

  h3,
  h2 {
    font-size: 1.5rem;
    margin: 1rem 0;
    font-weight: 600;
    line-height: 1.2;
  }

  .close {
    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 6;
    background: #f5f5f5;
  }
}

.tabFAQ {
  font-size: 15px;
  padding-bottom: 0 !important;

  h2 {
    text-align: center;
    margin-bottom: 15px;
  }

  .fixed {
    position: sticky;
    top: 0;
    background: #f5f5f5;
    z-index: 3;
    padding-top: 5px;
  }

  ._tabs {
    margin-bottom: 15px;
    border-radius: 30px;
    border: 1px solid #832cff;
    min-height: 40px;
    background-color: transparent;

    &::after {
      display: none;
    }
  }

  .txtTabs {
    min-height: 40px;
  }

  .txtTabs[aria-selected="true"] {
    background-color: #832cff !important;

    span {
      color: #fff;
    }
  }

  .TabPanel > div {
    padding: 0;
  }
}

.question {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    border-bottom: 1px dashed #dee1e5;
    margin-bottom: 15px;

    &:last-child {
      border-color: transparent !important;
    }

    p {
      font-size: 14px;
      padding-left: 30px;
      margin-bottom: 15px;

      a {
        color: #832cff;
      }
    }

    .headertxt {
      font-size: 15px;
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 30px;
      line-height: 1.6;
      margin-bottom: 12px;
      font-weight: 600;

      span[aria-expanded="true"] {
        background: #832cff;
        height: 2px;
        width: 12px;
        display: flex;
        position: absolute;
        left: 0;
      }

      span[aria-expanded="false"] {
        background: #832cff;
        height: 2px;
        width: 12px;
        display: flex;
        position: absolute;
        left: 0;

        &::after {
          content: "";
          background: #832cff;
          height: 12px;
          width: 2px;
          display: block;
          margin-right: 20px;
          position: absolute;
          left: 5px;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
    }
  }
}

.alert {
  padding: 12px 12px 16px;
  background: #e8f4fd;
  border: 1px solid #cee7fb;
  margin: 16px;

  p {
    font-size: 13px;
    line-height: 1.55;
    color: rgb(53, 60, 118);
    display: flex;

    span {
      display: block;
      margin-right: 6px;
      margin-left: -4px;
    }
  }

  .button {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    margin-top: 12px;
  }
}

.bgList {
  height: 100%;
  margin-top: 47px !important;

  .bgListBtn {
    background-color: #fff;
    padding-top: 4px;
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .bgListactive {
    background-color: #f3e6ff !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}

.closeLike {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  padding: 4px 16px;
  background-color: #832cff;
  color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  h2 {
    font-size: 1.2053571428571428rem;
    font-weight: 500;
    line-height: 1.6;
  }
}

.iconFavourite {
  button {
    color: #832cff;

    .number {
      color: #fff;
      right: 0;
      border-color: #fff;
    }
  }
}

/* DARK MODE */
.darkmode {
  .iconFavourite {
    button {
      color: #fff;
    }
  }

  .youtubeDialog {
    background-color: #121212;
    height: 100%;

    .uploadComplete {
      border-color: #2f2f2f;
    }

    .TextField {
      border-color: #2f2f2f;
      background: #1f1f1f;
      color: #fff;
    }
  }

  .upload li .image {
    background: #2f2f2f;
  }

  .uploadComplete {
    border-color: #2f2f2f;
  }

  .bgList {
    background-color: #121212;

    .bgListBtn {
      background-color: #1f1f1f;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bgListactive {
      background-color: #11001f !important;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
  }

  fieldset[aria-hidden] {
    border-color: #2f2f2f;
  }

  .errorPage .content .icon {
    background-image: url("../images/noconection-w.png");
  }

  .alert {
    border-color: #3e3e3e;
    background: #2f2f2f;

    p {
      color: #fff;
    }
  }

  .tabFAQ {
    .fixed {
      background-color: #121212;
    }

    .TabPanel {
      background-color: #121212;
    }
  }

  .question {
    li {
      border-color: #2f2f2f;
    }
  }

  .bgCs {
    background-color: #121212;

    .close {
      background-color: #121212;
    }
  }

  .btnDetails {
    .btn2 {
      button:nth-child(2) {
        &.Mui-disabled {
          color: #777777;
          box-shadow: none;
          background-color: #3a3a3a;
        }
      }
    }
  }

  .empty {
    color: #ccc;
    background-color: #121212;

    img {
      opacity: 1;
    }
  }

  .ChangePassword {
    .content {
      background: #121212;

      input {
        background: #121212;
      }
    }
  }

  .form-group {
    border-color: #2f2f2f;
  }

  background-color: #121212;
  color: #fbfbfb;

  .btnDetails {
    background-color: #1f1f1f;
  }

  .stickytabs {
    .width {
      background-color: #1f1f1f;
    }
  }

  .stickysearch {
    background-color: #121212;
  }

  .listJob {
    background: #1f1f1f;

    .listcontent {
      border-color: #2f2f2f;
    }
  }

  .seachInput {
    .txtSearch {
      background: #1f1f1f;
    }
  }

  .stickytabs .txtSearch {
    background: #2b2b2b;
  }

  .listFilter {
    background: #121212;

    .cardHeader {
      background: #121212;
      border-color: #2f2f2f;
    }

    .ExpansionPanel,
    .btnClear {
      border-color: #2f2f2f;
    }

    .cardcontent {
      background: #1f1f1f;
    }
  }

  .reviews {
    background: #1f1f1f;

    .comment li,
    .btnReview {
      border-color: #2f2f2f;
    }

    .rateout p {
      color: #fff;
    }
  }

  .contentDetail {
    .ExpansionPanel {
      background: #1f1f1f;
    }

    .ExpansionPanelSummary {
      background: #1f1f1f;
    }

    .ExpansionPanelDetails {
      border-color: #2f2f2f;
      background: #1f1f1f;
    }
  }

  .btnWhatapp {
    background-color: #121212;
  }

  .ratingbox {
    border-color: #2f2f2f;
    background-color: #121212;

    .type {
      .textarea {
        background: #1f1f1f;
      }
    }
  }

  .textfillBox {
    background: #1f1f1f;
  }

  .tabs {
    ._tabs {
      background-color: #121212;

      &::after {
        background: #1f1f1f;
      }
    }

    .txtTabs[aria-selected="true"] {
      background: #1f1f1f;
    }

    .tabschild {
      [aria-label] {
        background-color: #2b2b2b;
      }

      &::after {
        background: #1f1f1f;
      }
    }
  }

  .TabPanel,
  .follow .box {
    background: #1f1f1f;
  }

  .SideCategories {
    li {
      &:last-child {
        border-color: #121212;
      }

      .item {
        background: #1f1f1f;
        border-color: #121212;

        &:nth-child(1)::after {
          background: #121212;
        }
      }
    }
  }

  .listJobscroll {
    li {
      background: #1f1f1f;

      &:last-child {
        border-color: #121212;
      }
    }

    .listcontent {
      background: #1f1f1f;
    }
  }

  .links {
    background: #1f1f1f;

    > div,
    li {
      border-color: #121212;
    }
  }

  .comment {
    border-color: #2f2f2f;
  }

  .TextField {
    .Mui-disabled {
      color: #fff;
    }

    .Mui-error {
      color: #f44336;
    }
  }

  .About {
    .content {
      background-color: #121212;
      background-image: url("../images/bgDark1.svg");

      &::after {
        background-image: url("../images/bgDark2.svg");
      }

      .email {
        color: #fff;
      }
    }
  }

  .groupApplicants {
    .applicants {
      border-color: #2f2f2f;
    }
  }

  .tabsFollows {
    .TabPanel {
      background: #1f1f1f;
    }
  }

  .follow .group {
    background: #1f1f1f;
  }
}

.loading-new {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-new2 {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;

  .box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
  }
}

.multiline {
  white-space: pre-line;
}

.linkt {
  color: #832cff;
  text-decoration: none;
}

.eula {
  padding: 16px;
  font-size: 15px;

  h2 {
    margin-bottom: 15px;
    font-size: 1.17em;
  }

  h3 {
    margin-bottom: 15px;
  }

  p {
    line-height: 1.6;
    margin-bottom: 15px;
  }

  ul {
    padding-left: 16px;
    margin-bottom: 15px;

    li {
      list-style: disc;
      margin-bottom: 5px;
    }
  }
}

.slider {
  margin: 15px 0 20px;

  .slider-item {
    background-size: cover;

    img {
      width: 100%;
    }
  }

  .slick-dots {
    bottom: 10px;

    li {
      margin: 0 6px;
      width: 8px;
      height: 8px;

      button {
        width: 8px;
        height: 8px;
        background: rgba(255, 255, 255, 0.75);
        border-radius: 50%;
        padding: 0;

        &::before {
          display: none;
        }
      }

      &.slick-active {
        button {
          background: #832cff;
        }
      }
    }
  }
}
.dialogPhotos {
  [aria-describedby="alert-dialog-description"] {
    max-height: none;
  }
  .sliderPhoto {
    margin: 0;
  }
}
.dialogPhotos [aria-labelledby="alert-dialog-title"] {
  .slider-item img {
    width: 100vw;
    height: 100vh;
    object-fit: contain;
    max-width: 100%;
  }
  background-color: transparent;
  box-shadow: none;
  margin: 0;
  .close {
    position: fixed;
    top: 16px;
    right: 16px;
  }
}
.sliderPhoto {
  display: flex;
  align-items: center;
  .slick-track {
    display: flex;
    align-items: center;
  }
  .slick-slide {
    // padding:0 16px;
  }
  .slick-dots {
    position: fixed;
    bottom: 20px;
    left: 0;
  }
}

.notifications {
  position: relative;

  .number {
    background-color: #e80f0f;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 13px;
    position: absolute;
    top: 5px;
    right: 3px;
    border: 1px solid #832cff;
  }
}

.dialogReviewGigs {
  text-align: center;

  .avatar {
    width: 60px;
    height: 60px;
    margin: 0 auto 12px;
  }

  p {
    font-weight: 600;
    margin-bottom: 8px;
  }

  .type {
    width: 100%;
    margin: 16px 0 0;

    .textarea {
      width: 100%;
      background: #f5f5f5;

      textarea {
        min-height: 80px;
      }
    }
  }

  .thanks {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0 20px;
    font-size: 15px;

    .success {
      background-color: transparent;

      > div {
        margin: 0;
      }

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.darkmode {
  .dialogReviewGigs {
    .type {
      .textarea {
        background: #2f2f2f;
      }
    }
  }
}

.upload {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  align-items: flex-start;
  margin: 10px 0 20px;

  li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image {
      background: #f5f5f5;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      height: 100px;
      border-radius: 5px;
      margin-bottom: 5px;
      position: relative;

      input {
        position: absolute;
        width: 100%;
        height: 100px;
        opacity: 0;
      }
    }

    span {
      font-size: 11px;
      color: #777;
      display: block;
    }
  }
}

.uploadComplete {
  width: 100%;
  padding-bottom: 15px;
  margin: 15px 15px 0;
  border-bottom: 1px solid #dedede;

  label {
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
  }

  li {
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 12px;
    font-size: 13px;
    justify-content: space-between;

    .link {
      display: grid;
      grid-template-columns: 24px 1fr;
      grid-gap: 7px;
      align-items: center;

      p {
        margin: 0;
        opacity: 0.75;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
      }
    }

    svg {
      margin-right: 7px;
    }

    .cancel {
      padding: 5px;
      color: #8f8f8f;
      margin-right: 0;
    }
  }

  .photo {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;

    .image {
      position: relative;

      &:after {
        content: "";
        display: block;
        padding-top: 100%;
      }

      .content {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;

        img {
          width: 100%;
          object-fit: cover;
          height: 100%;
          object-position: top;
          border-radius: 5px;
        }
      }

      .cancel {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 50%;
        color: rgba($color: #fff, $alpha: 0.75);
      }
    }
  }
}

.youtubeDialog {
  width: 100%;
  padding: 15px 15px 0;
  margin-top: 55px;

  .uploadComplete {
    padding-bottom: 0;
    padding-top: 10px;
    margin: 10px 0 0;
    border-top: 1px solid #dedede;
    border-bottom: none;
  }

  .TextField {
    width: 100%;
    border: 2px dashed #dedede;
    padding: 8px 15px;
    font-family: Montserrat, Arial;
    font-weight: 500;
    line-height: 1.43;
    background-color: #f5f5f5;
    border-radius: 5px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    > div {
      &::after,
      &::before {
        border: none !important;
      }
    }
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
